import React, { Component } from 'react';
import { HtmlComponent, HtmlComponentContainer } from 'features/HtmlComponent';

/* "preamble" is now full "texteditor", "tinymceContent" is "heading" but only for side card. "flip" is alterante layout for side card.  */

class Card extends Component {

     onClickScroll = () => {
        const scrollToElement = document.getElementById('formLink');
         if (scrollToElement) {
             const windowOffset = window.scrollY || window.pageYOffset;

             const { top } = scrollToElement.getBoundingClientRect();
             const scrollY = top;
             window.scroll({
                 top: windowOffset + scrollY,
                 behavior: 'smooth',
                 left: 0
             });
         } 
    };

  // Because text imported into texteditor gets no markup.
  wrapInParagraph(str) {
    if (str.includes('<')) {
      return str;
    } else {
      return `<p>${str}</p>`;
    }
    }


  render() {
      const { tinymceContent, imgURL, texteditor, buttonURL, ButtonText, flip, numberOfCards, pictureRightSide, shouldScrollToForm } = this.props;
      const processedText = this.wrapInParagraph(texteditor); 

      var imgPaddingSize = 's'
      if (numberOfCards === 4)
          imgPaddingSize = 'xs'

      var imgRightOrLeftSide = 'pictureLeftSide'
      if (pictureRightSide) {
          imgRightOrLeftSide = "pictureRightSide"
      }

      if (
          buttonURL &&
          buttonURL !== 'null' &&
          buttonURL !== 'null#formLink' &&
          buttonURL !== 'undefined' &&
          buttonURL !== 'undefined#formLink'
      )
          return (
              <a href={buttonURL} className={`feature-card card-padding--${numberOfCards} ${imgRightOrLeftSide} placeContent`}>

                  {flip && tinymceContent && (
                      <div className="tinymce">
                          <h4>{tinymceContent}</h4>
                      </div>
                  )}
                  {imgURL &&
                      <img className={`feature-card__img img-mb--${imgPaddingSize}`} src={imgURL} alt="Bild till kort." />
                  }
                  {!flip && tinymceContent && (
                      <div className="tinymce">
                          <h4>{tinymceContent}</h4>
                      </div>
                  )}
                  <div className='content_container'>
                      {texteditor && (
                          <HtmlComponentContainer
                              smallmargin="0rem 0rem 1rem 0rem"
                              margin="0rem 0rem 1rem 0rem">
                              <HtmlComponent html={processedText} />
                          </HtmlComponentContainer>
                      )}
                      {ButtonText && (
                          <div className={`feature-card__button button button-width--${imgPaddingSize}`}>
                              {ButtonText}
                          </div>

                      )}
                  </div>
              </a>
          );

      else if (shouldScrollToForm)
          return (
              <div className={`feature-card hand card-padding--${numberOfCards} ${imgRightOrLeftSide} placeContent`}
                  onClick={() => {
                      this.onClickScroll();
                  }}>

                  {flip && tinymceContent && (
                      <div className="tinymce">
                          <h4>{tinymceContent}</h4>
                      </div>
                  )}
                  {imgURL &&
                      <img className={`feature-card__img img-mb--${imgPaddingSize}`} src={imgURL} alt="Bild till kort." />
                  }
                  {!flip && tinymceContent && (
                      <div className="tinymce">
                          <h4>{tinymceContent}</h4>
                      </div>
                  )}
                  <div className='content_container'>
                      {texteditor && (
                          <HtmlComponentContainer
                              smallmargin="0rem 0rem 1rem 0rem"
                              margin="0rem 0rem 1rem 0rem">
                              <HtmlComponent html={processedText} />
                          </HtmlComponentContainer>
                      )}
                      {ButtonText && (
                          <div className={`feature-card__button button button-width--${imgPaddingSize}`}>
                              {ButtonText}
                          </div>
                      )}
                  </div>
              </div>
          );
    return (
        <div className={`feature-card card-padding--${numberOfCards}`}>
        {flip && tinymceContent && (
          <div className="tinymce">
            <h4>{tinymceContent}</h4>
          </div>
        )}
            {imgURL && <img className={`feature-card__img img-mb--${imgPaddingSize}`} src={imgURL} alt="Bild till kort." />}
        {!flip && tinymceContent && (
          <div className="tinymce">
            <h4>{tinymceContent}</h4>
          </div>
            )}
            <div className='content_container'>
            {texteditor && <HtmlComponentContainer
                smallmargin="0rem 0rem 1rem 0rem"
                margin="0rem 0rem 1rem 0rem">
                <HtmlComponent html={processedText} />
                </HtmlComponentContainer>}
            </div>
      </div>
    );
  }
}

export default Card;